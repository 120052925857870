import React, {Component} from 'react';
import {Route, Switch} from 'react-router';
import Home from './Home';
import connect from 'react-redux/es/connect/connect';
import {bindActionCreators} from 'redux';
import {actionCreators as routerActions} from './store/RouterActions';
import {actionCreators as windActions} from './store/WindStore';
import {actionCreators as storyActions} from './store/StoryStore';
import * as DocumentStore from './store/DocumentStore';
import * as AppStore from './store/AppStore';
import Documents from './components/Documents';
import {StaticMap} from './components/StaticMap';
import {Danish} from 'flatpickr/dist/l10n/da';
import * as flatpickr from 'flatpickr';
import IdleTimer from 'react-idle-timer';
import Login from './components/Login';
import SafetyInduction from './components/safetyinduction/SafetyInduction';
import * as DeviceStore from './store/DeviceStore';
import * as InfoSlideStore from './store/InfoSlideStore';


document.addEventListener('contextmenu', event => event.preventDefault());

Date.prototype.getWeek = function () { // eslint-disable-line no-extend-native
    var onejan = new Date(this.getFullYear(), 0, 1);
    return Math.ceil((((this - onejan) / 86400000) + onejan.getDay() + 1) / 7);
};

class App extends Component {

    componentDidMount(){
        flatpickr.localize(Danish);

        this.props.appActions.refresh(localStorage.getItem("deviceId"));
        //this.props.appActions.refreshSlow();

        setInterval(() => {
            //TODO indsæt fra device state
            this.props.appActions.refresh(localStorage.getItem("deviceId"));//this.props.state.deviceActions.activeDevice.id);
        }, 1000*60*2);
        // setInterval(() => {
        //     this.props.appActions.refreshSlow();
        // }, 1000*60*15);
        
    }

    render(){
        return(
            <div>
                    {/* 

                <Switch>
                    */}

                    <Route exact path="/" component={Home} />
                    {/* 
                    <Route exact path="/documents" component={Documents}/>
                    <Route exact path='/map' component={StaticMap}/>
                    <Route exact path='/login' component={Login}/>
                    <Route exact path='/safetyinduction' component={SafetyInduction}/>
                </Switch>
                <IdleTimer
                    ref="idleTimer"
                    element={document}
                    idleAction={() => this.props.routerActions.navigate('/')}
                    timeout={60000} />
                    */}
            </div>
        );
    }
}

export default connect(
    state => ({state: state}),
    dispatch => ({
        routerActions: bindActionCreators(routerActions, dispatch),
        documentActions: bindActionCreators(DocumentStore.actionCreators, dispatch),
        storyActions: bindActionCreators(storyActions, dispatch),
        windActions: bindActionCreators(windActions, dispatch),
        appActions: bindActionCreators(AppStore.actionCreators, dispatch),
        deviceActions: bindActionCreators(DeviceStore.actionCreators, dispatch),
        infoSlideActions: bindActionCreators(InfoSlideStore.actionCreators, dispatch),

    })
)(App);