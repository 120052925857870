import axios from 'axios';
import config from '../config';
import {createAuth} from "../Helpers";

//export const reducerName = "windState";

const WIND_GET_RESPONSE = 'WIND_GET_RESPONSE';

const initialState = {
    wind: {}
};

export const actionCreators = {
    getWind: () => async(dispatch, getState) => {
        const windStationId = getState().deviceState.activeDevice.windStationId;

        axios({
            method: 'GET',
            url: `${config.server}/api/adminOptions/wind/${windStationId}`,
            withCredentials: true,
            auth: createAuth(getState)
        }).then(response => {
            dispatch({type: WIND_GET_RESPONSE, wind: {...response.data}});
        }).catch(error => {
            console.log("ERROR FETCHING WIND", error);
        });
    }
};

const reducerMethods = {
    WIND_GET_RESPONSE: (state, action) => {
        return{
            ...state,
            wind: {...action.wind}
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};