/*import axios from 'axios';
import config from '../config';
import {createAuth} from '../Helpers';
*/

export const reducerName = 'deviceState';

const DEVICE_GETALL_RESPONSE = 'DEVICE_GETALL_RESPONSE';
const SET_ACTIVE_DEVICE = 'SET_ACTIVE_DEVICE';


/*
Man kan på hver gruppe tiføje en prop, som definerer placering og størrelse af vind-visning
        windPlacement: {
            left: '85.5vw', 
            top: '21vh', 
            width: '6.6vh'
        }
*/

const groups = [
    {
        deviceIds: [4, 5],
        group: 'AVV',
        windStationId: '06183',
        rotation: -187.00, // rotationen på baggrundsbilledet ift. nord - RIGTIG
    },
    {
        deviceIds: [6],
        group: 'ESV',
        windStationId: '06081',
        rotation: -187.95,
        windPlacement: {
            left: '88vw', 
            top: '4.6vh', 
            width: '5.5vh',
            error: {
                top: '5.4vh'
            }
        },
        compassPlacement: {
            left: '77.5vw', 
            top: '5vh', 
            height: 14
        }
    },
    {
        deviceIds: [7],
        group: 'HEV',
        windStationId: '06108',
        rotation: -58.17,
        windPlacement: {
            left: '85vw', 
            top: '5vh', 
            width: '6.6vh',
            error: {
                top: '6vh'
            }
        },
        compassPlacement: {
            left: '72vw', 
            top: '5vh', 
            height: 17
        }
    },
    {
        deviceIds: [8],
        group: 'KYV',
        windStationId: '06156',
        rotation: -309.22,
        windPlacement: {
            error: {
                left: '86vw',
                top: '20vh',
                width: '4vh',
            }
        },
    },
    {
        deviceIds: [9, 10],
        group: 'ASV',
        windStationId: '06068',
        rotation: -0.71,
        windPlacement: {
            left: '78.5vw', 
            top: '5vh', 
            width: '5.5vh',
            error: {
                top: '6.5vh',
            }
        },
        compassPlacement: {
            left: '85vw', 
            top: '5vh', 
            height: 16
        }
    },
    {
        deviceIds: [11, 12, 13],
        group: 'SSV',
        windStationId: '06073',
        rotation: -39.95,
        windPlacement: {
            left: '88vw', 
            top: '4vh', 
            width: '5.5vh',
        },
        compassPlacement: {
            left: '77.5vw', 
            top: '4vh', 
            height: 14
        }
    },
    {
        deviceIds: [14, 15],
        group: 'SKV',
        windStationId: '06159',
        rotation: -179.73,
        windPlacement: {
            left: '87vw',
            top: '22vh',
            error: {
                left: '88vw',
                top: '22vh',
                width: '4vh',
            }
        },
        compassPlacement: {
            left: '85vw', 
        },

    },

]

const initialState = {
    devices: groups,
    activeDevice: {}
};


export const actionCreators = {
    getAll: () => async(dispatch, getState) => {

        dispatch({type: DEVICE_GETALL_RESPONSE, data: groups});

        const deviceId = localStorage.getItem('deviceId');

        if(deviceId){
            dispatch({type: SET_ACTIVE_DEVICE, deviceId: deviceId});
        }

        /*
        const deviceId = localStorage.getItem('deviceId');

        if(deviceId){
            axios({
                method: 'GET',
                url: `${config.server}/api/device/${deviceId}`,
                withCredentials: true,
                auth: createAuth(getState)
            }).then(response => {
                dispatch({type: DEVICE_GETALL_RESPONSE, data: response.data})
            }).catch(error => {
    
            });
        }
        */
    },
    setActiveDevice: () => async(dispatch, getState) => { // BRUGES IKKE SO FAR

        const deviceId = localStorage.getItem('deviceId');

        if(deviceId){
            dispatch({type: SET_ACTIVE_DEVICE, deviceId: deviceId});
        }



    }

};

const reducerMethods = {
    DEVICE_GETALL_RESPONSE: (state, action) => {
        return{
            ...state,
            devices: action.data
        }
    },
    SET_ACTIVE_DEVICE: (state, action) => {

        let activeDevice = state.devices.filter(group => group.deviceIds.includes(Number(action.deviceId)));

        activeDevice = activeDevice.length > 0 ? activeDevice[0] : {};

        return{
            ...state,
            activeDevice: activeDevice
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};