import {actionCreators as documentActions} from './DocumentStore';
import {actionCreators as windActions} from "./WindStore";
import {actionCreators as storyActions} from "./StoryStore";
import {actionCreators as deviceActions} from './DeviceStore';
import {actionCreators as infoSlideActions} from './InfoSlideStore';

export const reducerName = 'appStore';

const APP_REFRESH_EVERYTHING = 'APP_REFRESH_EVERYTHING';


export const actionCreators = {
    refresh: (deviceId) => async(dispatch) => {
        dispatch({type: APP_REFRESH_EVERYTHING});
        dispatch(deviceActions.getAll());
        dispatch(infoSlideActions.getAll(deviceId));
    },
    refreshSlow: () => async(dispatch) => {
    }
};
