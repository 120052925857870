import React from 'react';
import PropTypes from 'prop-types';

import './button.css';
export function PlayPauseBtn(props) {
    if (props.isPlaying) {
        return <button id="stop-btn" className='player-btn' onClick={props.onStop}>
            <span role='img' aria-label='Stop'>
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" className="bi bi-stop-circle vertically-align" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3z" />
                </svg>
            </span></button>
    } else
        return (
            <button id="start-pause-btn" className='player-btn' onClick={props.onPlay}>
                <span role='img' aria-label='Play'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" className="bi bi-play-circle vertically-align" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                    </svg>

                </span>
            </button>)
}

export function ForwardBtn(props) {
    return <button id="forward-btn" className='player-btn' onClick={props.onClick}>
        <span role='img' aria-label='Forward'>
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" fillOpacity={props.enabled ? "1" : "0.5"} className="bi bi-arrow-right-circle vertically-align" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
            </svg>
        </span>
    </button>;
}
export function BackBtn(props) {
    return <button id="back-btn" className='player-btn' onClick={props.onClick}>
        <span role='img' aria-label='Back'>
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" fillOpacity={props.enabled ? "1" : "0.5"} className="bi bi-arrow-left-circle vertically-align" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
            </svg>
        </span>
    </button>;
}
export function RestartBtn(props) {
    return <button id="restart-btn" className='player-btn restart-btn' onClick={props.onClick}>
        <span role='img' aria-label='BackToStart'>
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" fillOpacity={props.enabled ? "1" : "0.5"} className="vertically-align" viewBox="0 0 512 512"><path d="M109.7 160H160c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V64C0 46.3 14.3 32 32 32s32 14.3 32 32v51.2L81.6 97.6c87.5-87.5 229.3-87.5 316.8 0s87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3s-163.8-62.5-226.3 0L109.7 160z"/></svg>
        </span>
    </button>;
}

PlayPauseBtn.propTypes = {
    isPlaying: PropTypes.bool,
    onPlay: PropTypes.func,
    onStop: PropTypes.func
}
BackBtn.propTypes = {
    enabled: PropTypes.bool,
    onClick: PropTypes.func
}

ForwardBtn.propTypes = {
    enabled: PropTypes.bool,
    onClick: PropTypes.func
}
RestartBtn.propTypes = {
    enabled: PropTypes.bool,
    onClick: PropTypes.func
}


