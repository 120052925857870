import axios from 'axios';
import config from '../config';
import {createAuth} from '../Helpers';


export const reducerName = 'infoSlideState';

const INFOSLIDE_GETALL_RESPONSE = 'INFOSLIDE_GETALL_RESPONSE';
const SET_ACTIVE_DEVICE = 'SET_ACTIVE_DEVICE';

const initialState = {
    infoSlides: [],
    //activeDevice: {}
};

export const actionCreators = {
    getAll: (deviceId) => async(dispatch, getState) => {

        if(deviceId){

            axios({
                method: 'GET',
                url: `${config.server}/api/infoSlide/device/${deviceId}`,
                withCredentials: true,
                auth: createAuth(getState),
            }).then(response => {
                dispatch({type: INFOSLIDE_GETALL_RESPONSE, data: response.data})
            }).catch(error => {
    
            });
        }
        
    },
    // setActiveDevice: () => async(dispatch, getState) => { // BRUGES IKKE SO FAR

    //     const deviceId = localStorage.getItem('deviceId');

    //     if(deviceId){
    //         dispatch({type: SET_ACTIVE_DEVICE, deviceId: deviceId});
    //     }
    // }

};

const reducerMethods = {
    INFOSLIDE_GETALL_RESPONSE: (state, action) => {


        console.log(`Antal slides fundet: ${action.data.length}`);
        action.data.forEach(slide => {
            console.log(slide.name);
        });

        return{
            ...state,
            infoSlides: action.data
        }
    },
    // SET_ACTIVE_DEVICE: (state, action) => {

    //     let activeDevice = state.devices.filter(group => group.deviceIds.includes(Number(action.deviceId)));

    //     activeDevice = activeDevice.length > 0 ? activeDevice[0] : {};

    //     return{
    //         ...state,
    //         activeDevice: activeDevice
    //     }
    // }
};

export const reducer = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};