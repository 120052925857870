import React from 'react';
import { connect } from 'react-redux';
import './Compass.css';
import './Element.css';
import compassImage from '../../images/kompas_med_rettede_bogstaver.png';


import PropTypes from 'prop-types';

const Compass = props => {


    const compassPlacement = {
        left: '83.2vw',
        top: '5vh',
        height: 15,
        ...props.compassPlacement
    }

    /*
    const left = props.compassPlacement.left != undefined ? props.compassPlacement.left : 83.2;
    const top = props.compassPlacement.top || 5;
    const height = props.compassPlacement.height || 15;
    */

    const heightToWidthRatio = 9/16;
    const width = compassPlacement.height * heightToWidthRatio;


    const compassStyling = {
        left: compassPlacement.left,
        top: compassPlacement.top,
        width: width + 'vw',
        height: compassPlacement.height + 'vh',
        transform: `rotate(${props.degreesToRotate}deg)`
    }


    function renderCompass () {
        return ( 
        < div id="compass" className = {'compass-wrapper'} style = {compassStyling} >
                <img src={compassImage} className="compass-image" alt=''></img>
        </div >
        )
    };

    return renderCompass();

};

Compass.propTypes = {
    src: PropTypes.string,
    width: PropTypes.number,
    coordinates: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number
    }),
}
export default connect(
    //state => ({state: state}) // lige nu laver den ikke compass-component ud fra state
)(Compass);


