import React from 'react';
import Clock from 'react-live-clock';
import { actionCreators as routerActions } from '../store/RouterActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import logoImage from '../images/customer_logo.png';

import './Menu.css';

const Menu = (props) => {
    return (
        <div className="menu">
            <Clock format={'HH:mm'} ticking={true} timezone={'Europe/Copenhagen'} interval={30000} />
            <div className="menu-point-container">
                <img style={{ position: 'absolute', bottom: 0, left: 0, opacity: 1 }} src={logoImage} alt='' />
            </div>

        </div>
    );
};

export default connect(
    state => state,
    dispatch => ({
        routerActions: bindActionCreators(routerActions, dispatch)
    })
)(Menu);