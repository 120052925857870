import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators as infoSlideActions } from "../store/InfoSlideStore";
import "./InfoSlide.css";
import "../css/OrstedSans.css";

const InfoSlide = (props) => {
  const infoSlidesState = { ...props.infoSlideState };

  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);
  const defaultDelay = 20000;

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(
    () => {
      resetTimeout();
      timeoutRef.current = setTimeout(
        () => {
          if (infoSlidesState.infoSlides != undefined) {
            setIndex((prevIndex) =>
              prevIndex === infoSlidesState.infoSlides.length - 1
                ? 0
                : prevIndex + 1
            );
          }
        },
        infoSlidesState.infoSlides[index] === undefined
          ? defaultDelay
          : infoSlidesState.infoSlides[index].duration * 1000
      );

      return () => {
        resetTimeout();
      };
    },
    [index]
  );

  const styling = {
    border: "none",
    height: "100vh",
    width: "100vw",
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 100,
  };

  console.log(infoSlidesState.infoSlides[index])

  const renderSlide = (slide) => {
    return <iframe style={styling} srcDoc={slide.htmlPage} />;
  };

  return (
    <div className="slideshow">
      { infoSlidesState.infoSlides[index] !== undefined && renderSlide(infoSlidesState.infoSlides[index]) }
    </div>
  );
};

export default connect(
  (state) => ({
    infoSlideState: state.infoSlideState,
  }),
  (dispatch) => ({
    infoSlideActions: bindActionCreators(infoSlideActions, dispatch),
  })
)(InfoSlide);
